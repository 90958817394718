import '../../styles/pages/spring-summer-2024/presidents-message.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2024-stories/more-stories"
import Gallery from "react-photo-gallery";
import Vimeo from '@u-wave/react-vimeo';

import Hero from '../../images/spring-summer-2024/presidents-message/presidents-message-hero-1920x1080.jpg';
import socialBanner from "../../images/spring-summer-2024/social-banners/presidents-message-social-banners-1200x630.jpg";
import titelGraphic from "../../images/spring-summer-2024/presidents-message/presidents-message-title-380x245.svg";

import presidentsmessageIMG1 from '../../images/spring-summer-2024/presidents-message/presidents-message-image-1-800x533.jpg';
import alvaSignature from '../../images/spring-summer-2024/presidents-message/Sylvia-A-Alva-Signature.svg';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'A Message From Cal State Fullerton President Sylvia Alva';
    var pageDesc = 'Cal State Fullerton President Sylvia Alva introduces the spring/summer 2024 issues of Titan magazine and the university’s Fullerton Forward 2024-29 strategic plan.';
    var slug = 'presidents-message';

    var heroStyles = {
      backgroundImage: `url(${Hero})`,
    }

    const photos = [
      // {
      //   src: exampleImage1,
      //   width: 800,
      //   height: 533,
      //   alt: '',
      //   title: ''
      // },
      // {
      //   src: exampleImage2,
      //   width: 533,
      //   height: 800,
      //   alt: '',
      //   title: ''
      // },
      // {
      //   src: exampleImage3,
      //   width: 800,
      //   height: 553,
      //   alt: '',
      //   title: ''
      // },
      // {
      //   src: exampleImage4,
      //   width: 800,
      //   height: 553,
      //   alt: '',
      //   title: ''
      // }
    ];

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2024">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`spring-summer-2024 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s"><img className='titelGraphic shadow' src={titelGraphic} alt="President's Message" /></h1>
            {/* <p className="sub-heading animated fadeInDown delay-1s">The De La Cruzes are keeping scholarships in the family, with three siblings in the President’s Scholars program and one in the Abrego Future Scholars program.
</p> */}
            {/* <span className="animated fadeInDown delay-1s byline">By Debra Cano Ramos / Photos By Matt Gush</span> */}
          </div>
          {/* <div className="tint"></div> */}
        </div>
        <article className='wrap' id="copy-wrap">

          <div className='wrap small'>

          <p><span className="intro-text">Cal State Fullerton&rsquo;s  future shines bright!</span></p>
<p>We are a place where our commitment to student success drives all that we do and where the transformational power of education is exemplified by our alumni who make meaningful contributions in their communities and professions.</p>
<p>This issue of Titan magazine is filled with stories of inspiration, opportunity and success.</p>
<p>For example, nearly 32% of our students are the first in their families to attend college. While those numbers are impressive, it helps to share stories that embody the talent, determination and success of our first-gen students. In the article “A Family of Titan Scholars,” we get to know first-gen siblings Pablo, Rebeca, Andy, Ana and Alejandro De La Cruz, whose hard work and academic achievements fit perfectly within the prestigious scholarship programs in the Center for Scholars. Their family story and accomplishments help us understand why the Center for First-Generation Student Success named Cal State Fullerton a First-Gen Forward Institution.</p>
<figure className='align-wide'>
            <img src={presidentsmessageIMG1} alt="" />
            <figcaption>President Sylvia Alva with Cal State Fullerton students</figcaption>
</figure>
<p>We also share how alumna Christina House won a Pulitzer Prize in 2023 for feature photography by documenting the heart-wrenching story of a 22-year-old pregnant woman living on Hollywood&rsquo;s streets. Her lens amplified awareness for one of the most challenging societal issues of our time: housing insecurity.</p>
<p>Geology alumni Michelle Barboza-Ramirez and Gabriel-Philip Santos co-host the PBS show &ldquo;Eons,&rdquo; taking viewers on a journey through the prehistoric past. The pair are advocates for diverse representation in the sciences, with Barboza-Ramirez identifying as Latinx, queer and nonbinary, and Santos identifying as Filipino American. Representation matters.</p>
<p>I am lifted up by the impact Titans have and am filled with optimism that Cal State Fullerton is prepared to embody our vision to &ldquo;serve as a model public university that cultivates leaders who drive innovation, systemic change, and collective well-being in local and global communities.&rdquo;</p>
<p>Our new vision is a pivotal piece of the <a href="https://planning.fullerton.edu/" title="Fullerton Forward 2024-29" target="_blank">Fullerton Forward 2024-29</a> strategic plan, which launched in February. This aspirational plan, which you can read more about in this issue of Titan magazine, springs from past accomplishments and is a bold vision for the future, rooted in shared values.</p>
<p>I think of Fullerton Forward as a constellation, with academic and student success as our North Star. All of our Titans are stars within this constellation, and each will have a unique way to contribute to our successes.</p>
<p>I am confident that with this plan as our guide, Cal State Fullerton is well-positioned for success in the years to come, and that we will have an endless supply of successful alumni to feature in Titan magazine.</p>
<p>Together, we will move Fullerton Forward! <span className="end-mark" /></p>

<p style={{lineHeight: '1.7em'}}>
            Sincerely,<br /><br />
            <img src={alvaSignature} alt="Sylvia A. Alva, Ph.D." width="200px" /><br />
            Sylvia A. Alva, Ph.D.<br />
            President<br />
            California State University, Fullerton
          </p>

</div>
        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 